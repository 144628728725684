
import { defineComponent, reactive, toRefs, ref } from 'vue'
import demandApi from '@/axios/api/demand'
import { useRouter } from 'vue-router'
import store from '@/store/index'
import Data from '@/model/common/Data'
import { Toast, Notify } from 'vant'
export default defineComponent({
  setup() {
    const router = useRouter()
    const formRef = ref()
    const pageData = reactive({
      subForm: {
        companyName: '', //公司名称
        contact: '', //联系人
        contactPhone: '', //联系人手机号
        demandProduct: '', //需求产品名称
        deviceName: '', //应用设备名称
        demandCount: '', //预计需求数量
        useEnv: '', //使用环境
        demandDate: '', //需求时间
        demandDesc: '', //需求描述
        fileIdList: '', //文件集合
      },
      fileIdList: [] as string[],
      fileList: [],
      show: false,
      loading: false,
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
      flag: false,
    })
    // 提交事件
    const onSubmit = () => {
      console.log('提交事件', pageData.subForm)
      formRef.value
        .validate()
        .then(() => {
          console.log('验证成功')
          pageData.loading = true
          if (pageData.subForm.demandDate && pageData.subForm.demandDate.length === 10)
            pageData.subForm.demandDate = pageData.subForm.demandDate + ' 00:00:00'
          pageData.subForm.fileIdList = pageData.fileIdList.join(',')
          pageData.flag = true
          demandApi
            .DemandAdd(pageData.subForm)
            .then(() => {
              pageData.loading = false
              pageData.flag = false
              Notify({ type: 'success', message: '提交成功' })
              router.push(`/success`)
            })
            .catch((err) => {
              pageData.loading = false
              pageData.flag = false
              Notify({ type: 'primary', message: err })
              if (err?.includes('未认证')) {
                store.commit('setDemandForm', pageData.subForm)
                router.push({
                  path: '/phoneValidation',
                  query: {
                    phone: pageData.subForm.contactPhone,
                  },
                })
              }
            })
        })
        .catch(() => {
          console.log('验证失败')
        })
    }
    //选自日期事件
    const confirm = (val: Date) => {
      let month = '' + (val.getMonth() + 1)
      let day = '' + val.getDate()
      let year = val.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      pageData.subForm.demandDate = [year, month, day].join('-')
      pageData.show = false
    }
    const upload = (file: Blob, detail: Data) => {
      console.log(file, detail)
      const formData = new FormData()
      formData.append('file', file)
      return new Promise((response, rej) => {
        pageData.loading = true
        demandApi
          .uploadFile(formData)
          .then((res) => {
            pageData.loading = false
            console.log(pageData.fileList)
            pageData.fileIdList.push(res)
            response(file)
          })
          .catch((err) => {
            pageData.loading = false
            Toast(err || '提交失败')
            rej()
          })
      })
    }
    const deleteFile = (file: Blob, detail: Data) => {
      console.log(detail)
      pageData.fileIdList.splice(detail.index as number, 1)
      return true
    }
    return {
      ...toRefs(pageData),
      onSubmit,
      confirm,
      upload,
      formRef,
      deleteFile,
    }
  },
})
